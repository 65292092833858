@tailwind components;
@tailwind utilities;

h5,
p {
  text-align: justify;
}

.main_contact {
  position: fixed;
  top: 70%;
  right: -90px;
  z-index: 7777777777;
  transform: translate(23%, -50%);
  transition: 500ms;

  &:hover {
    transform: translate(-50%, -50%);
  }

  .main_contact_container {
    position: relative;
    top: 0;
    // height: 320px;

    & .contact_img {
      // position: absolute;
      cursor: pointer;
      top: 0;
      border: 1px solid #fff;
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
      background-color: #fff;
      border-radius: 5px;
      width: max-content;
      & a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-wrap: nowrap;
        gap: 10px;
        margin: 0 5px;
        font-size: 14px;
        font-weight: 400;
        color: #0d2847;
        font-weight: 600;
        letter-spacing: 1px;
      }
      & img {
        // margin: 8px 8px;
      }
    }

    & .contact_number {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      overflow: hidden;
      transition: 500ms;

      & span {
        color: #0d2847;
        margin: 0px;
        padding: 0px;
        font-weight: 600;
        height: 25px;
      }
      // line-height: 0.05;
    }
    // &:hover {
    //   position: fixed;
    //   right: -70px;
    // }
  }
}
