/* custom css */
.subscribe-style-05 form button {
  background: #ff6403 !important;
  margin-top: 25px;
  position: static !important;
  padding: 15px 20px !important;
  border-color: #ff6403 !important ;
  transition: 0.4s;
}
.subscribe-style-05 form button:hover {
  opacity: 0.8;
}
.subscribe-style-05 form .form_group {
  position: relative;
  margin-bottom: 25px;
}
.subscribe-style-05 form .error_message {
  position: absolute;
  color: tomato;
  font-size: 12px;
  line-height: 1;
  transform: translateY(2px);
}

// number input spinner
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.bg_image {
  padding: 0px !important;
  margin: 0px !important;
}

.main_modal_form {
  background-color: #0d2847;
}

// media query start

@media only screen and (max-width: 991px) {
  .bg_image {
    background-position: top;
  }
}
