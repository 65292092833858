.whatsappBtn {
    width: fit-content;
    border-radius: 4px;
    padding: 10px;
    background: #075e54;
}

.whatsappBtn:hover {
    background: #25D366;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    a {
        text-decoration: none;
        &:hover {
            color: #fff;
        }
    }
}

.callBtn {
    width: fit-content;
    border-radius: 4px;
    padding: 10px;
    background: #ff6402;
    & i{
        transform: rotate(90deg);
    }
}

.callBtn:hover {
    background: #a13e00;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    a {
        text-decoration: none;
        &:hover {
            color: #fff;
        }
    }
}