.star-rating {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: auto;
  color: #f78c1f;
  height: 1em;
  line-height: 1;
  letter-spacing: 4px;
  font-family: "Font Awesome 5 Free";

  &::before {
    color: #f78c1f;
    content: "\f005\f005\f005\f005\f005";
    font-size: 12px;
    line-height: 16px;
  }

  span {
    display: inline-block;
    overflow: hidden;
    position: absolute;
    left: 0;
    color: #f78c1f;
    padding-top: 1.5em;
    white-space: nowrap;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      font-weight: 600;
      content: "\f005\f005\f005\f005\f005";
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.whitespace-balance {
  white-space: balance;
}

// top university section start
// line animation of top university start
.university_section {
  .title {
    font-size: 17px;
    font-weight: 400;
    .line_icon {
      z-index: 1;
      position: relative;
      width: fit-content;
      p {
        font-weight: 500;
      }
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        transform: translate(-50%, -50%);
        overflow: visible;
      }

      svg path {
        stroke: #f78c1f;
        stroke-width: 9;
        fill: none;
        opacity: 0;
        stroke-dasharray: 0 1500;
        transition: 0.3s;

        animation: showLine 4s ease-in-out 0s infinite running;

        &:nth-of-type(2) {
          animation-delay: calc(var(1200ms, 1.2s) / 2);
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .university_section {
    .title {
      font-size: 12px;
      font-weight: 400;
    }
  }
}
@media only screen and (max-width: 400px) {
  .university_section {
    .title {
      font-size: 11px;
      font-weight: 400;
    }
  }
}
@keyframes showLine {
  0% {
    stroke-dasharray: 0 1500;
    opacity: 1;
  }
  10% {
    stroke-dasharray: 1500 1500;
    opacity: 1;
  }

  60% {
    stroke-dasharray: 1500 1500;
    opacity: 1;
  }
  90% {
    stroke-dasharray: 0 1500;
    opacity: 1;
  }
  91% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

// line animation of top university end

.university_cards {
  // .block-animation {
  //   width: 120px;
  //   height: 120px;
  //   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  //   transition: 0.6s ease-in-out;
  //   border: 0px #fff;
  //   margin-top: 15px;
  //   margin-left: 20px;
  //   border-radius: 8px;
  //   background-color: #fff;
  // }
  .block-animation {
    // min-width: 80px;
    height: fit-content;
    padding: 10px 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    transition: 0.6s ease-in-out;
    border: 0px #fff;
    margin: 10px 10px;

    border-radius: 8px;
    background-color: #fff;
  }

  // .block-animation:hover {
  //   transform: scale(1.1);
  // }

  // .block-animations {
  //   // min-width: 120px;
  //   height: fit-content;
  //   padding: 3px 10px;
  //   margin-top: 15px;
  //   margin-left: 20px;
  //   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  //   transition: 0.6s ease-in-out;
  //   border: 0px #fff;
  //   border-radius: 8px;
  //   background-color: rgb(226, 226, 226);
  // }

  .block-animations:hover {
    transform: scale(1.1);
  }

  .imgs-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40%;
    width: 100%;
  }
}
// top university section end

// top university card
.clip_card {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  clip-path: polygon(0 75%, 0 0, 100% 0, 100% 75%, 50% 100%);
  // min-height: 150px;
}
.clip_card_medical {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  clip-path: polygon(0 75%, 0 0, 100% 0, 100% 75%, 50% 100%);
  // min-height: 150px;
  background-color: #f2f0eb;
}
